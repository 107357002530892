



















import { Component, Vue } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import DeviceInfo from '@/components/common/DeviceInfo.vue'
import deviceCollection, { Device } from '@/models/device'
import AuthStore from '@/store/AuthStore'
@Component<OwnerDeviceIndex>({
  components: { DeviceInfo },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class OwnerDeviceIndex extends Vue {
  unsubscribe: firebase.Unsubscribe | null = null
  doc: firebase.firestore.DocumentSnapshot | null = null
  get phoneNumber (): string {
    if (!AuthStore.firebaseUser) return ''
    return AuthStore.firebaseUser.phoneNumber || ''
  }

  subscribe (): void {
    if (!this.phoneNumber) return
    this.unsubscribe = deviceCollection.doc(this.phoneNumber).onSnapshot(doc => {
      if (!doc.exists) return
      this.doc = doc
    })
  }
}
